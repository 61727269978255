<template>
  <div class="all-topic-assignment-submissions">
    <v-bottom-sheet
      scrollable
      :value="visible"
      :width="$vuetify.breakpoint.mdAndDown ? '100%' : '60%'"
      @click:outside="$emit('close')"
    >
      <v-card>
        <v-card-title>All Submissions</v-card-title>
        <v-card-subtitle v-if="totalCount" class="pb-2 pt-1"
          >Total Submissions: {{ totalCount }}</v-card-subtitle
        >
        <v-card-text class="px-2">
          <intract-smart-list
            paginated
            :endpoint="endpoint"
            :item-options="itemOptions"
            :filterFields="filterFields"
            @updateTotalCount="(c) => (totalCount = c)"
          >
            <template v-slot:list-item-content="{ item: submission }">
              <v-list-item-content>
                <v-list-item-title>{{
                  submission.student.full_name
                }}</v-list-item-title>
                <v-list-item-subtitle
                  >{{
                    submission.documents_count
                  }}
                  Documents</v-list-item-subtitle
                >
                <v-list-item-subtitle>{{
                  moment(submission.created).format("lll")
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <template v-slot:list-item-right="{ item: submission }">
              <v-list-item-icon class="align-self-center">
                <v-chip color="green lighten-4" small v-if="submission.accepted"
                  >Accepted</v-chip
                >
                <v-chip color="red lighten-4" small v-else>Pending Acceptance</v-chip>
              </v-list-item-icon>
            </template>
          </intract-smart-list>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import IntractSmartList from "@components/generics/IntractSmartList";
import Mixins from "@utils/mixins";
import moment from "moment";
export default {
  name: "AllTopicAssignmentSubmissions",
  mixins: [Mixins.essentials],
  components: {
    IntractSmartList,
  },
  props: {
    assignmentId: {
      type: Number,
      default: null,
    },
    routeName: {
      type: String,
      default: null,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    endpoint() {
      return this.Helper.addUrlParams(
        this.endpoints.topicAssignmentSubmissions,
        "topic_assignment=" + this.assignmentId
      );
    },
    filterFields() {
      return {
        categories: {
          model: null,
          items: [
            { title: "Pending", value: false },
            { title: "Accepted", value: true },
          ],
          label: "Filter by Status",
          itemValue: "value",
          itemText: "title",
          param: "accepted",
        },
      };
    },
  },
  data() {
    return {
      moment: moment,
      totalCount: null,
      itemOptions: {
        avatar: "student.image",
        handleClick: (item) => {
          this.$emit("close");
          this.$router.push({
            name: this.routeName + "TopicAssignmentSubmission",
            params: { submissionId: item.id },
          });
        },
      },
    };
  },
};
</script>