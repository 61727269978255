<template>
  <div class="topic-assignment-page">
    <v-card v-if="assignment && assignment.is_draft" color="yellow lighten-4">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap body-2"
            >This Topic Assignment is marked as
            <strong>'Draft'</strong></v-list-item-title
          >
        </v-list-item-content>

        <v-list-item-icon v-if="canManage"
          ><v-btn @click="showPublishDialog = true" small color="primary"
            ><v-icon class="mr-2">mdi-publish</v-icon> Publish</v-btn
          ></v-list-item-icon
        >
      </v-list-item>
    </v-card>
    <v-row justify="center">
      <v-col md="6">
        <div class="mx-4">
          <!-- assignment description -->
          <div class="my-2 mx-2" v-if="assignment">
            <p
              class="content-description"
              style="word-break: break-word"
              v-html="
                TextHelper.linkify(
                  Helper.markuptext(assignment.description, '*', 'strong')
                )
              "
            ></p>
          </div>
          <v-divider
            v-if="assignment && assignment.topic_assignment_documents.length"
          />
          <!-- assignment documents -->
          <v-list
            class="my-0 py-0"
            v-if="assignment && assignment.topic_assignment_documents.length"
          >
            <v-list-item
              class="px-0"
              color="red"
              v-for="assignmentDocument in assignment.topic_assignment_documents"
              :key="assignmentDocument.id"
            >
              <v-list-item-avatar>
                <v-icon>mdi-file</v-icon>
              </v-list-item-avatar>
              <v-list-item-content class="py-0">
                <v-list-item-subtitle
                  class="font-weight-bold"
                  v-if="assignmentDocument.file_name.split('.').length > 1"
                >
                  {{
                    assignmentDocument.file_name
                      .split(".")
                      .slice(-1)[0]
                      .toUpperCase()
                  }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="subtitle-2 text-wrap">
                  {{ assignmentDocument.file_name }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon class="align-center">
                <v-btn
                  outlined
                  color="primary"
                  class="non-focused"
                  v-if="assignmentDocument.progress == 100"
                  @click="openFile(assignmentDocument)"
                  ><v-icon>mdi-check</v-icon> Open</v-btn
                >
                <v-btn
                  @click="downloadDocument(assignmentDocument)"
                  color="primary"
                  class="non-focused"
                  icon
                  v-else
                >
                  <v-icon v-if="assignmentDocument.progress == null"
                    >mdi-download</v-icon
                  >
                  <span v-else>{{ assignmentDocument.progress }}%</span>
                </v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </div>
      </v-col>
    </v-row>

    <!-- publish dialog -->
    <confirmation-dialog
      title="Are you sure you want to publish this Assignment?"
      description="This will notify all the students from this classroom that the Topic Assignment has been published."
      :visible="showPublishDialog"
      @successCallback="publishAssignment"
      @failureCallback="showPublishDialog = false"
    />
    <!-- delete dialog -->
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this assignment?"
      description="This action cannot be undone"
      @successCallback="deleteAssignment"
      @failureCallback="showDeleteDialog = false"
      delete-dialog
    />
    <!-- create edit sheet -->
    <intract-create-edit-sheet
      persistent
      v-if="!currentUser.is_student"
      class="assignment-create-edit-sheet"
      :title="
        assignmentSheet.editId
          ? 'Edit Topic Assignment'
          : 'Create Topic Assignment'
      "
      description="An assignment for students to better understand the topic"
      :visible="assignmentSheet.visible"
      @close="
        assignmentSheet.visible = false;
        assignmentSheet.editId = null;
      "
      :fields="assignmentFormFields"
      :endpoint="endpoints.topicAssignments"
      :edit-id="assignmentSheet.editId"
      :data-object="assignmentSheet.obj"
      :create-success-message="assignmentSheet.createSuccessMessage"
      :edit-success-message="assignmentSheet.editSuccessMessage"
      enable-media
      @objectCreated="getAssignmentDetails()"
      @updateObject="(obj) => (assignmentSheet.obj = obj)"
    />
    <!-- submit assignment sheet (student) -->
    <intract-create-edit-sheet
      v-if="currentUser.is_student"
      class="assignment-submission-create-edit-sheet"
      title="Submit Assignment"
      description="Write a description about your submission and attach necessary files"
      :visible="submissionSheet.visible"
      @close="submissionSheet.visible = false"
      :fields="submissionFormFields"
      :endpoint="endpoints.topicAssignmentSubmissions"
      :edit-id="submissionSheet.editId"
      :data-object="submissionSheet.obj"
      :create-success-message="submissionSheet.createSuccessMessage"
      enable-media
      @objectCreated="
        (obj) =>
          $router.push({
            name: this.routeName + 'TopicAssignmentSubmission',
            params: { submissionId: obj.id },
          })
      "
      @updateObject="(obj) => (submissionSheet.obj = obj)"
    />
    <all-topic-assignment-submissions
      v-if="canManage"
      :assignment-id="Number($route.params.assignmentId)"
      :routeName = "this.routeName"
      :visible="showSubmissions"
      @close="showSubmissions = false"
    />
    <!-- staff submission management section -->
    <!-- <v-toolbar v-if="canManage" bottom absolute min-width="100%"> -->
    <v-footer
      v-if="canManage"
      color="white"
      app
      fixed
      class="justify-center py-2 ma-auto"
      elevation="10"
    >
      <p class="pa-0 ma-0">
        <strong v-if="assignment"
          >{{ assignment.total_submissions_count }} Submissions</strong
        >
      </p>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="showSubmissions = true">View All</v-btn>
    </v-footer>
    <!-- student submission section -->
    <v-footer
      v-if="currentUser.is_student"
      color="white"
      app
      fixed
      class="justify-center py-2 ma-auto"
      elevation="10"
    >
      <p class="pa-0 ma-0">
        <strong v-if="submission" class="green--text"
          >Assignment Submitted!</strong
        >
        <strong v-else class="red--text">Assignment Not Submitted</strong>
      </p>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        v-if="submission"
        @click="
          $router.push({
            name: routeName + 'TopicAssignmentSubmission',
            params: { submissionId: submission.id },
          })
        "
        >View</v-btn
      >
      <v-btn color="primary" v-else @click="submissionSheet.visible = true"
        >Submit</v-btn
      >
    </v-footer>
  </div>
</template>
<script>
import TextHelper from "@utils/text_helper";
import Mixins from "@utils/mixins";
import ConfirmationDialog from "@components/dialogs/ConfirmationDialog";
import EventBus from "@utils/event_bus";
import { mapActions } from "vuex";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import AllTopicAssignmentSubmissions from "@components/books/AllTopicAssignmentSubmissions";
export default {
  name: "TopicAssignment",
  mixins: [
    Mixins.essentials,
    Mixins.pullToRefresh,
    Mixins.handleAppBarTitle,
    Mixins.downloadEssentials,
  ],
  components: {
    ConfirmationDialog,
    IntractCreateEditSheet,
    AllTopicAssignmentSubmissions,
  },
  props: {
    assignmentTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      routeName: this.$route.meta.is_activity? "ActivitySheet" : "Book",
      TextHelper,
      appBarTitle: this.assignmentTitle,
      initialImage: null,
      pr: null,
      showSubmissions: false,
      assignmentSheet: {
        visible: false,
        createSuccessMessage: "Topic Assignment created successfully!",
        editSuccessMessage: "Topic Assignment edited successfully!",
        editId: null,
        obj: {
          book_topic: null,
          is_draft: false,
          creator: null,
        },
      },
      submissionSheet: {
        visible: false,
        createSuccessMessage: "Assignment submitted successfully!",
        editId: null,
        obj: {
          student: null,
          topic_assignment: this.$route.params.assignmentId,
        },
      },
      showPublishDialog: false,
      assignment: null,
      showDeleteDialog: false,
      showVideo: false,
      videoLink: null,
      submission: null, // student: used to check if assignment was submitted
    };
  },
  computed: {
    documentsList() {
      return this.assignment.topic_assignment_documents;
    },

    canManage() {
      return this.assignment && this.currentUser.is_faculty;
    },

    assignmentFormFields() {
      return {
        title: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Title",
          required: true,
          md: 12,
          max: 255,
        },
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        is_draft: {
          fieldType: CreateEditFieldTypes.CHECKBOX,
          label: "Save as draft?",
          md: 12,
          helper:
            "Enabling this will save this topic as draft and will not be shown to your students",
        },
        topic_assignment_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },

    submissionFormFields() {
      return {
        description: {
          fieldType: CreateEditFieldTypes.TEXTAREA,
          label: "Description",
          required: true,
          md: 12,
          max: 3000,
        },
        topic_assignment_submission_documents: {
          fieldType: CreateEditFieldTypes.FILEUPLOAD,
          multiple: true,
          label: "Documents",
          helper: "PDF, Video, Audio, Docs (Upto 30MB)",
        },
      };
    },
  },
  methods: {
    ...mapActions(["setAppBarOptions", "resetAppBarOptions"]),

    async getAssignmentDetails() {
      var url =
        this.endpoints.topicAssignments + this.$route.params.assignmentId + "/";
      this.assignment = await this.api.call(this.essentials, url);
      EventBus.$emit("topic_assignment__assignment_updated", this.assignment);
      this.appBarTitle = this.assignment.title;
      this.assignmentSheet.obj.book_topic = this.assignment.book_topic;
      this.checkIfDocumentsDownloaded();
      if (this.currentUser.is_student) this.checkIfAssignmentSubmitted();
    },

    async publishAssignment() {
      this.showPublishDialog = false;

      var url = this.endpoints.topicAssignments + this.assignment.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { is_draft: false }
      );
      if (response) {
        this.assignment.is_draft = false;
        this.showSnackbar({
          title: "Topic Assignment has been published",
          text: "Students of this classroom will be notified!",
          type: "success",
        });
        EventBus.$emit("topic_assignment__assignment_updated", this.assignment);
      }
    },

    async deleteAssignment() {
      this.showDeleteDialog = false;
      var url =
        this.endpoints.topicAssignments + this.$route.params.assignmentId + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Topic Assignment successfully deleted",
          type: "success",
        });
        EventBus.$emit(
          "topic_assignment__assignment_deleted",
          this.$route.params.assignmentId
        );
        this.$router.back();
      }
    },

    async checkIfAssignmentSubmitted() {
      var url = this.Helper.addUrlParams(
        this.endpoints.topicAssignmentSubmissions,
        [
          "topic_assignment=" + this.assignment.id,
          "student=" + this.currentUser.id,
        ]
      );
      var response = await this.api.call(this.essentials, url);
      if (response.count != 0) this.submission = response.results[0];
    },

    async setAppBar() {
      this.setAppBarOptions({
        title: this.appBarTitle,
        actions: [
          {
            id: 1,
            title: "Edit",
            icon: "mdi-pencil",
            onClick: () => {
              this.assignmentSheet.visible = true;
              this.assignmentSheet.editId = Number(
                this.$route.params.assignmentId
              );
            },
          },
          {
            id: 2,
            title: "Delete",
            icon: "mdi-delete",
            onClick: () => (this.showDeleteDialog = true),
          },
        ],
      });
    },

    onRefresh() {
      this.getAssignmentDetails();
    },
  },

  beforeDestroy() {
    this.resetAppBarOptions();
  },

  async created() {
    await this.getAssignmentDetails();
    if (this.canManage) this.setAppBar();
    if (this.currentUser.is_student)
      this.submissionSheet.obj.student = this.currentUser.id;
    else this.assignmentSheet.obj.creator = this.currentUser.id;
  },
};
</script>